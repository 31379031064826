<script setup lang="ts">
import type { NuxtError } from 'nuxt/app';

import { useHead } from '#imports';

defineProps<{
    error: NuxtError;
}>();

useHead({
    htmlAttrs: {
        dir: 'ltr',
    },
    style: [{
        innerHTML:
            `:root {
              --font-headings: Quicksand;
              --font-text: Nunito;
            }`,
    }],
});
</script>

<template>
    <NuxtLayout name="basic">
        <h2>
            <Icon v="exclamation-triangle" />
            {{ error.statusCode }}
            {{ error.name }}
            {{ error.message }}
        </h2>

        <details class="border">
            <summary class="bg-light p-3">
                Additional Information
            </summary>
            <pre class="p-2" v-html="error.stack"></pre>
            {{ error.cause }}
        </details>

        <p class="h4 mt-4">
            <nuxt-link to="/">
                <Icon v="chevron-circle-left" />
                <T>notFound.back</T>
            </nuxt-link>
        </p>

        <AdPlaceholder :phkey="['content-0', 'content-mobile-0']" />
    </NuxtLayout>
</template>
